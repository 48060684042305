import React from "react"
import propTypes from "prop-types"
import deepmerge from "deepmerge"

import {
  colors,
  letterSpacings,
  fontSizes,
  fonts,
  mediaQueries,
  space,
} from "../../utils/presets"
import { guideContainer } from "../../utils/styles"

const styles = {
  quickLinksContainer: {
    display: `flex`,
    position: `relative`,
    zIndex: 1,
    justifyContent: `flex-start`,
    [mediaQueries.desktop]: {
      justifyContent: `flex-end`,
    },
  },
  quickLinks: {
    marginRight: 0,
    marginTop: space[9],
    [mediaQueries.desktop]: {
      marginRight: -200,
      marginTop: -25,
    },
  },
  quickLinksTitle: {
    fontSize: fontSizes[1],
    color: colors.gatsby,
    fontFamily: fonts.system,
    letterSpacing: letterSpacings.mega,
    marginTop: 0,
    marginBottom: space[3],
    textTransform: `uppercase`,
    [mediaQueries.desktop]: {
      fontSize: fontSizes[0],
      color: colors.lilac,
    },
  },
  quickLinksTitleDivider: {
    height: 1,
    width: space[7],
    backgroundColor: colors.gatsby,
    marginBottom: space[6],
    [mediaQueries.desktop]: {
      backgroundColor: colors.lilac,
      marginBottom: space[3],
    },
  },
  quickLinksList: {
    listStyle: `none`,
    padding: 0,
    margin: 0,
    display: `flex`,
    justifyContent: `flex-start`,
    [mediaQueries.desktop]: {
      display: `block`,
    },
  },
  quickLinkListItem: {
    marginRight: space[6],
    [mediaQueries.desktop]: {
      marginBottom: space[1],
      marginRight: 0,
    },
  },
  quickLink: {
    color: colors.gray.calm,
    textDecoration: `none`,
    [mediaQueries.desktop]: {
      fontSize: fontSizes[1],
    },
    "&:hover": {
      color: colors.gatsby,
    },
  },
}

const QuickLinks = ({ quickLinks }) => (
  <div css={deepmerge(styles.quickLinksContainer, guideContainer)}>
    <div css={styles.quickLinks}>
      <h4 css={styles.quickLinksTitle}>Quick Links</h4>
      <div css={styles.quickLinksTitleDivider} />
      <ul css={styles.quickLinksList}>
        {quickLinks.map(link => (
          <li key={link.displayTitle} css={styles.quickLinkListItem}>
            <a href={link.url} css={styles.quickLink}>
              {link.displayTitle}
            </a>
          </li>
        ))}
      </ul>
    </div>
  </div>
)

QuickLinks.propTypes = {
  quickLinks: propTypes.arrayOf(propTypes.object).isRequired,
}

export default QuickLinks
