import { graphql } from "gatsby"
import React from "react"
import propTypes from "prop-types"
import windowSize from "react-window-size"

import Layout from "../../layouts"
import Metaball from "../../components/metaball"
import QuickLinks from "./quick-links"
import HeroContainer from "../../components/containers/hero"
import ContentBlock from "../../components/content-block"
import Header from "../../components/header"
import Title from "../../components/hero/title"
import Subtitle from "../../components/hero/subtitle"
import Footer from "../../components/footer"
import LinkToParentPage from "../../components/link-to-parent-page"
import SEO from "../../components/seo"
import PullBelowHeader from "../../components/containers/pull-below-header"

import { rhythm } from "../../utils/typography"
import { mediaQueries, transition, space } from "../../utils/presets"
import {
  guideContentBlockStyles,
  guideContainer,
  guidePaddingBottom,
  anchorStyles,
} from "../../utils/styles"

const styles = {
  container: {
    overflowX: `hidden`,
    position: `relative`,
    zIndex: 2,
  },
  contentBlock: {
    ...guideContentBlockStyles,
  },
  partnerLogo: {
    height: `auto`,
    left: -16,
    position: `absolute`,
    top: 200,
    width: 240,
    transition: `opacity ${transition.speed.default} ${
      transition.curve.default
    }`,
    "@media (max-width: 1440px)": {
      opacity: `0.15`,
    },
    "@media (max-width: 750px)": {
      display: `none`,
    },
    "& img": {
      width: `100%`,
    },
  },
}

class DefaultGuidePage extends React.Component {
  render() {
    const { contentfulPage: page, nav } = this.props.data
    const {
      backgroundColor,
      contentBlocks,
      keyvisual,
      quickLinks,
      subtitle,
      title,
    } = page

    return (
      <Layout pathname={this.props.location.pathname}>
        <SEO contentfulPage={page} />
        <Header navItems={nav.edges} />
        <PullBelowHeader>
          <div
            css={{
              ...styles.container,
              ...guidePaddingBottom,
              position: `relative`,
            }}
          >
            <Metaball fill={backgroundColor} right />
            <HeroContainer isInverted>
              <div css={guideContainer}>
                {page.parentPage && <LinkToParentPage contentfulPage={page} />}
                {!!keyvisual && (
                  <div css={styles.partnerLogo}>
                    <img
                      src={keyvisual.asset.file.url}
                      alt={`${keyvisual.name} Logo`}
                    />
                  </div>
                )}
                <Title
                  customStyles={{
                    maxWidth: rhythm(16),
                    marginBottom: space[12],
                    [mediaQueries.phablet]: {
                      maxWidth: rhythm(20),
                    },
                  }}
                >
                  {title}
                </Title>
                <Subtitle
                  customStyles={{
                    maxWidth: rhythm(18),
                    [mediaQueries.phablet]: {
                      maxWidth: rhythm(22),
                    },
                  }}
                >
                  {subtitle.subtitle}
                </Subtitle>
              </div>
            </HeroContainer>
            {quickLinks && !!quickLinks.length && (
              <QuickLinks quickLinks={quickLinks} />
            )}
            <div css={{ ...anchorStyles, ...guideContainer }}>
              {contentBlocks.map(cb => (
                <div key={cb.id} css={styles.contentBlock}>
                  <ContentBlock contentBlock={cb} />
                </div>
              ))}
            </div>
          </div>
        </PullBelowHeader>

        <Footer navItems={nav.edges} />
      </Layout>
    )
  }
}

DefaultGuidePage.propTypes = {
  data: propTypes.object.isRequired,
  location: propTypes.object.isRequired,
}

export default windowSize(DefaultGuidePage)

export const MainNavigationFragment = graphql`
  fragment MainNavigation on Query {
    nav: allContentfulPage(filter: { showInTopNav: { eq: true } }) {
      edges {
        node {
          name
          slug
          weight
          parentPage {
            name
            slug
          }
        }
      }
    }
  }
`

export const pageQuery = graphql`
  query GuidePage($id: String!) {
    ...MainNavigation
    contentfulPage(id: { eq: $id }) {
      name
      title
      subtitle {
        subtitle
      }
      backgroundColor
      keyvisual {
        name
        asset {
          file {
            url
          }
        }
      }
      parentPage {
        name
        slug
        parentPage {
          name
          slug
        }
      }
      quickLinks {
        displayTitle
        url
      }
      ...ContentBlocks
      ...SocialMediaImage
    }
  }
`
